import React, { useState, useEffect } from "react";
// import { Bar } from "react-chartjs-2";
import { connect } from "react-redux";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useMediaQuery } from "react-responsive";
import "../preMonthChart.css";
import { abbreviateNumber } from "js-abbreviation-number";
import moment from "moment";

const ElectricityChart = React.memo(({ 
  theme, data_count, past_data_count, loading,
  from_ts, to_ts, past_from_ts, past_to_ts,
}) => {
  const [count, setCount] = useState([0, 0, 0, 0]);
  const isDesktop = useMediaQuery({ minWidth: 1268 });
  const isMobile = useMediaQuery({ maxWidth: 768 });

  useEffect(() => {
    if (data_count && data_count.graph && data_count.graph.electricity_used) {
      let cloneCount = [];

      Object.entries(data_count.graph.electricity_used).map(([key, value]) => {
        let peakObj = {
          uv: 0,
          pv: 0,
          pvv: "100%",
          title: key,
          name: moment(from_ts, "YYYY-MM-DD").format("YYYY"), 
          // moment(from_ts, "YYYY-MM-DD").format("YYYY") + " " + key,
          name2: moment(past_from_ts, "YYYY-MM-DD").format("YYYY"),
          // moment(past_from_ts, "YYYY-MM-DD").format("YYYY") + " " + key,
        };
        
        if (value !== 0) {
          peakObj["uv"] = value;
        }
        if (past_data_count.graph.electricity_used[key] !== 0) {
          peakObj["pv"] = past_data_count.graph.electricity_used[key];
        }
        cloneCount.push(peakObj);
      });
      let result = cloneCount.map((y) => y.uv);
      let maxValue = Math.max.apply(null, result);

      // cloneCount.map((c) => {
      //   // c.pv = maxValue;
      //   c.pv = "100%";
      // });

      setCount(cloneCount);
    }
  }, [data_count]);
  const formatter = (value) => `${abbreviateNumber(value).toLocaleString()}`;

  var tooltip;
  const CustomTooltip = ({ active, payload }) => {
    if (!active || !tooltip) return null;
    for (const bar of payload)
      if (bar.dataKey === tooltip) {
        return (
          <div
            style={{
              background: "rgba(0,0,0,.8)",
              borderRadius: 8,
              color: "white",
              fontWeight: "bold",
              fontSize: 11,
              padding: 8,
              minWidth: 50,
            }}
          >
            {localStorage.getItem("selectedDateMethod").toLowerCase() === "year"? (
              <div>
                  <div>{bar.payload.title}</div>
                  <div>{bar.payload.name2} : {
                    bar.payload.pv == "-" || bar.payload.pv == 0
                    ? "0 kWh"
                    : `${Number(bar.payload.pv).toLocaleString()} kWh`
                  }</div>
                  <div>{bar.payload.name} : {
                    bar.payload.uv == "-" || bar.payload.uv == 0
                    ? "0 kWh"
                    : `${Number(bar.payload.uv).toLocaleString()} kWh`
                  }</div>
              </div>
            ) : (
              <div>
                <div>{bar.payload.title}</div>
                <div>{`${Number(bar.payload.uv).toLocaleString()} kWh`}</div>
              </div>
            )}
          </div>
        );
      }

    return null;
  };
  return (
    <div
      className={`data_chart_container ${theme} ${
        count.length >= 12 ? "over_12_bars" : null
      }`}
    >
      <h1
        className="title"
        style={{
          width: "200",
          color: theme == "dark" && "#fff",
        }}
      >
        Electricity
        <br /> used(kWh)
      </h1>
      <div style={{ height: 180 }}>
        {loading ? 
          <div className="text-center" style={{ marginTop: "90px" }}>
              <div className="spinner-grow spinner-grow-sm text-primary" role="status">
                <span className="sr-only"></span>
              </div> &nbsp;
              <div className="spinner-grow spinner-grow-sm text-primary" role="status">
                <span className="sr-only"></span>
              </div> &nbsp;
              <div className="spinner-grow spinner-grow-sm text-primary" role="status">
                <span className="sr-only"></span>
              </div>
          </div>: (
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={550}
              height={300}
              data={count}
              margin={{
                top: 20,
              }}
              barCategoryGap={"78%"}
            >
              <CartesianGrid strokeDasharray="" />
              <XAxis
                dataKey="title"
                // dx={-7}
                interval={0}
                angle={isDesktop ? 0 : -45}
              />
              <YAxis
                tickFormatter={formatter}
                tick={{ fontSize: 13 }}
                width={50}
                domain={[0, `dataMax`]}
              />
              <Tooltip cursor={false} content={<CustomTooltip />} />
              {localStorage.getItem("selectedDateMethod").toLowerCase() === "year"? (
                <>
                  <Bar
                    dataKey="uv"
                    stackId="b"
                    fill="#00A6EF"
                    // barSize={10}
                    // radius={[10, 10, 0, 0]}
                    onMouseOver={() => (tooltip = "uv")}
                  />
                  <Bar
                    dataKey="pv"
                    stackId="a"
                    // barSize={10}
                    fill="#8fd44e"
                    // radius={[10, 10, 0, 0]}
                    onMouseOver={() => (tooltip = "uv")}
                  />
                </>
              ): (
                <>
                  <Bar
                    dataKey="pvv"
                    stackId="a"
                    barSize={10}
                    fill="#E5E5F1"
                    radius={[10, 10, 0, 0]}
                  />

                  <Bar
                    dataKey="uv"
                    stackId="a"
                    fill="#00A6EF"
                    barSize={10}
                    radius={[10, 10, 0, 0]}
                    onMouseOver={() => (tooltip = "uv")}
                  />
                </>
              )}
            </BarChart>
          </ResponsiveContainer>
          )}
      </div>
    </div>
  );
});

const mapStateToProps = (state) => ({
  loading: state.data.loading,
  from_ts: state.data.from_ts,
  to_ts: state.data.to_ts,
  past_from_ts: state.data.past_from_ts,
  past_to_ts: state.data.past_to_ts,
  data_count: state.data.data_count,
  past_data_count: state.data.past_data_count
});

export default connect(mapStateToProps, null)(ElectricityChart);
