import React, { useEffect, useState } from "react";
import { Route } from "react-router";
import { connect } from "react-redux";
import { useLocation, Redirect } from "react-router-dom";
import bootstrap from "bootstrap";
import { Modal } from "react-bootstrap";
import { useIdleTimer } from "react-idle-timer";

import {
  getUserLoginDetail,
  userLogout,
  storeCSRF,
  storeLoggedInFlag,
  getuserloginDetailSuccess,
} from "./store/actions/loginUserAction";
import config from "./config/config";
import api from "./config/api";

// routes
import {
  LOGIN_ROUTE,
  DASHBOARD_ROUTE,
  LIVE_ROUTE,
  DATA_ROUTE,
  BILLING_ROUTE,
  BILLING_PDF_ROUTE,
  RESOURCES_ROUTE,
  NOTIFICATION_ROUTE,
  CONTACT_ROUTE,
  PROFILE_ROUTE,
  USER_MANAGEMENT_ROUTE,
  FORGOT_PASSWORD_ROUTE,
} from "./routes";

// views
import Navigation from "./components/navigation";
import LoginForm from "./views/login/";
import Dashboard from "./views/dashboard";
import Live from "./views/live";
import Data from "./views/Data";
import Billing from "./views/Billing";
import Resources from "./views/Resources";
import Notification from "./views/notification";
import Contact from "./views/contact";
import Profile from "./views/profile";
import UserManagement from "./views/user_management";
import ForceResetPassword from "./views/forceResetPassword";

// css & logos
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import logo from "./logo.svg";

function App(props) {
  const {
    GET_CSRF_TOKEN,
    CHECK_SESSION,
    USER_DETAILS,
    LOGOUT_USER,
    GET_TOKEN,
    GET_SITE,
  } = config.api_endpoint;

  let location = useLocation();

  let selected = location.pathname.split("/")[1];
  var site_from_url = location.pathname.split("/");
  site_from_url = site_from_url[site_from_url.length - 1];

  const [imgToken, setImgToken] = useState("");
  const theme = localStorage.getItem("theme");
  const [loading, setLoading] = useState(true);
  const [signedIn, setSignedIn] = useState(props.AuthLoggedIn);

  if (!theme) {
    localStorage.setItem("theme", "light");
  }

  const onIdle = () => {
    console.log(
      "=== onIdle ===",
      props.AuthLoggedIn,
      props.AuthDetail.should_expire_session
    );
    if (props.AuthLoggedIn === true) {
      if (props.AuthDetail.should_expire_session) {
        // document.getElementById("btnLogout").click();

        api
          .post(LOGOUT_USER, JSON.stringify({}), props.CSRFToken)
          .then((result) => {
            console.log("app.js logout result: ", result);
            props.storeLoggedInFlag(false);
            props.userLogout();
            localStorage.setItem("theme", "light");
            localStorage.removeItem("selected_blocks_datapage");
            localStorage.removeItem("selected_site");
            window.location.href = "/";
          })
          .catch((err) => {
            console.log("app.js logout err:", err);
            props.storeLoggedInFlag(false);
            window.location.href = "/";
          });
      }
    }
  };

  const onActive = (event) => {
    console.log("=== onActive ===");
  };

  // const idleTimer = useIdleTimer({
  //   timeout: 1000 * 60 * 10,
  //   onIdle: onIdle,
  //   onActive: onActive,
  // });

  const checkTimeout = () => {
    var waitTime = 10 * 60 * 1000 + 5000; // 10 minutes
    var executionTime;
    var initialTime = localStorage.getItem("checkTimeoutValue");
    if (initialTime === null) {
      localStorage.setItem("checkTimeoutValue", new Date().getTime());
      executionTime = waitTime;
    } else {
      executionTime =
        parseInt(initialTime, 10) + waitTime - new Date().getTime();
      if (executionTime < 0) executionTime = 0;
    }
    setTimeout(function () {
      console.log("setTimeout check session");
      api
        .get(CHECK_SESSION)
        .then((res) => {
          if (res["status"] === "Error") {
            props.storeLoggedInFlag(false);
          }
        })
        .catch((err) => console.log("err", err));
      localStorage.removeItem("checkTimeoutValue");
    }, executionTime);
  };

  const changeBackground = () => {
    if (localStorage.getItem("theme") === "light") {
      document.body.classList.remove("root-dark");
      document.body.classList.add("root-light");
    } else {
      document.body.classList.remove("root-light");
      document.body.classList.add("root-dark");
    }
  };

  changeBackground();

  const checkCurrentSite = () => {
    if (!props.AuthLoggedIn) {
      return;
    }

    console.log(">>> checkCurrentSite");

    var url_splits = location.pathname.split("/");
    var should_add_site = true;
    var existing_site = "";
    var two_blanks = 0;
    var if_set_password = false;

    if (!localStorage.getItem("all_sites")) {
      api
        .get(GET_SITE)
        .then((res) => {
          var re = [];
          res["results"].forEach((item, i) => {
            re.push(item["name"]);
          });
          localStorage.setItem("all_sites", re);
          // do things here
          url_splits.forEach((item, i) => {
            if (
              localStorage.getItem("all_sites").indexOf(item) > -1 &&
              item != "" &&
              item != "set_password"
            ) {
              should_add_site = false;
              existing_site = item;
            }
            if (item == "set_password") {
              should_add_site = false;
              if_set_password = true;
            }
            if (item == "") {
              two_blanks += 1;
            }
          });
          if (two_blanks == 2) {
            should_add_site = false;
          }
          if (should_add_site) {
            window.location.href +=
              "/" + document.getElementById("kaer_current_now").value;
          } else {
            if (props.AuthDetail.company) {
              var site_permitted = false;

              props.AuthDetail.company.forEach((item, i) => {
                if (item["name"] == existing_site) {
                  site_permitted = true;
                }
              });

              if (site_permitted) {
                document.getElementById("kaer_current_now").value =
                  existing_site;
              } else {
                if (two_blanks != 2 && if_set_password == false) {
                  alert("You are not allowed to access " + existing_site + ".");
                  window.location.href = window.location.href.replace(
                    existing_site,
                    document.getElementById("kaer_current_now").value
                  );
                }
              }
            }
          }
        })
        .catch((err) => console.log("err", err));
    } else {
      // do things here
      url_splits.forEach((item, i) => {
        if (
          localStorage.getItem("all_sites").indexOf(item) > -1 &&
          item != "" &&
          item != "set_password"
        ) {
          should_add_site = false;
          existing_site = item;
        }
        if (item == "set_password") {
          should_add_site = false;
          if_set_password = true;
        }
        if (item == "") {
          two_blanks += 1;
        }
      });
      if (two_blanks == 2) {
        should_add_site = false;
      }
      if (should_add_site) {
        window.location.href +=
          "/" + document.getElementById("kaer_current_now").value;
      } else {
        if (props.AuthDetail.company) {
          var site_permitted = false;

          props.AuthDetail.company.forEach((item, i) => {
            if (item["name"] == existing_site) {
              site_permitted = true;
            }
          });

          if (site_permitted) {
            document.getElementById("kaer_current_now").value = existing_site;
          } else {
            if (
              location.pathname != "/" &&
              existing_site &&
              if_set_password == false
            ) {
              alert("You are not allowed to access " + existing_site + ".");
              window.location.href = window.location.href.replace(
                existing_site,
                document.getElementById("kaer_current_now").value
              );
            }
          }
        }
      }
    }
  };

  const checkCurrentSiteWithData = (userData) => {
    console.log(">>> checkCurrentSiteWithData");

    var url_splits = location.pathname.split("/");
    var should_add_site = true;
    var existing_site = "";
    var two_blanks = 0;
    var if_set_password = false;

    api
      .get(GET_SITE)
      .then((res) => {
        var re = [];
        res["results"].forEach((item, i) => {
          re.push(item["name"]);
        });
        localStorage.setItem("all_sites", re);
        // do things here
        url_splits.forEach((item, i) => {
          if (
            localStorage.getItem("all_sites").indexOf(item) > -1 &&
            item != "" &&
            item != "set_password"
          ) {
            should_add_site = false;
            existing_site = item;
          }
          if (item == "set_password") {
            should_add_site = false;
            if_set_password = true;
          }
          if (item == "") {
            two_blanks += 1;
          }
        });

        if (two_blanks == 2) {
          should_add_site = false;
        }
        if (should_add_site) {
          window.location.href +=
            "/" + document.getElementById("kaer_current_now").value;
        } else {
          if (userData.company) {
            var site_permitted = false;

            userData.company.forEach((item, i) => {
              if (item["name"] == existing_site) {
                site_permitted = true;
              }
            });

            if (site_permitted) {
              document.getElementById("kaer_current_now").value = existing_site;
            } else {
              if (
                location.pathname != "/" &&
                existing_site &&
                if_set_password == false
              ) {
                alert("You are not allowed to access " + existing_site + ".");
                window.location.href = window.location.href.replace(
                  existing_site,
                  document.getElementById("kaer_current_now").value
                );
              }
            }
          }
        }
      })
      .catch((err) => console.log("err", err));
  };

  // const refresh_interval = localStorage.getItem("refresh_interval");
  //
  // if (!refresh_interval) {
  //   localStorage.setItem("refresh_interval", 1);
  // }

  useEffect(() => {
    api
      .get(GET_TOKEN)
      .then((res) => {
        setImgToken(res["results"]["token"]);
        localStorage.setItem("imgToken", res["results"]["token"]);
      })
      .catch((err) => {
        console.log("Image token err", err);
      });

    if (
      !document.getElementById("kaer_current_now").value &&
      props.AuthDetail.company
    ) {
      document.getElementById("kaer_current_now").value =
        props.AuthDetail.company[0].name;
    }
    checkCurrentSite();
  }, []);

  useEffect(() => {
    setLoading(true);
    api
      .get(CHECK_SESSION)
      .then((res) => {
        if (res["status"] === "Error") {
          props.storeLoggedInFlag(false);
          localStorage.removeItem("selected_site");
          setLoading(false);
        } else {
          // checkTimeout();

          api
            .get(GET_TOKEN)
            .then((res) => {
              setImgToken(res["results"]["token"]);
              localStorage.setItem("imgToken", res["results"]["token"]);
            })
            .catch((err) => {
              console.log("Image token err", err);
            });

          if (props.AuthLoggedIn === false) {
            props.storeLoggedInFlag(true);
          }
          if (!props.AuthDetail.username) {
            // props.getUserLoginDetail(res["username"]);
            api
              .get(USER_DETAILS, {
                username: res["username"],
              })
              .then((res) => {
                props.getuserloginDetailSuccess(res["results"]);

                if (!document.getElementById("kaer_current_now").value) {
                  document.getElementById("kaer_current_now").value =
                    res["results"].company[0].name;
                }

                if (!res["results"]["need_reset_password"]) {
                  checkCurrentSiteWithData(res["results"]);
                } else {
                  if (selected != "set_password") {
                    window.location.href = "/set_password";
                  }
                }

                api
                  .get(GET_CSRF_TOKEN)
                  .then((res) => {
                    console.log("CSRF api result: ", res["csrf"]);
                    props.storeCSRF(res["csrf"]);
                    setLoading(false);
                  })
                  .catch((err) => console.log("err", err));
              })
              .catch((err) => {
                console.log("err", err);
              });
          } else {
            if (!document.getElementById("kaer_current_now").value) {
              document.getElementById("kaer_current_now").value =
                props.AuthDetail.company[0].name;
            }

            if (
              props.AuthDetail.need_reset_password &&
              selected != "set_password"
            ) {
              window.location.href = "/set_password";
            } else {
              checkCurrentSite();
            }

            api
              .get(GET_CSRF_TOKEN)
              .then((res) => {
                console.log("CSRF api result: ", res["csrf"]);
                props.storeCSRF(res["csrf"]);
                setLoading(false);
              })
              .catch((err) => console.log("err", err));
          }
        }
      })
      .catch((err) => console.log("err", err));
  }, [selected]);

  return (
    <div>
      {loading === true ? (
        <Modal
          show={loading}
          data-backdrop="static"
          data-keyboard="false"
          className="loader"
        >
          <div className="text-center">
            <div className="spinner-border" role="status">
              <span className="sr-only"></span>
            </div>
          </div>
        </Modal>
      ) : !loading && selected == "set_password" ? (
        <ForceResetPassword setSignIn={props.storeLoggedInFlag} />
      ) : !loading && props.AuthLoggedIn === true ? (
        <Navigation
          selected={selected}
          img_token={imgToken}
          setSignIn={props.storeLoggedInFlag}
        >
          <Route
            exact={true}
            path={`${DASHBOARD_ROUTE}/:siteId`}
            component={Dashboard}
          />
          <Route path={`${DATA_ROUTE}/:siteId`} component={Data} />
          <Route path={`${LIVE_ROUTE}/:siteId`} component={Live} />
          <Route path={`${BILLING_ROUTE}/:siteId`} component={Billing} />
          <Route
            path={[
              `${RESOURCES_ROUTE}/:siteId`,
              `${RESOURCES_ROUTE}/:resource_type/:siteId`,
            ]}
            component={Resources}
          />
          <Route
            path={`${NOTIFICATION_ROUTE}/:siteId`}
            component={Notification}
          />
          <Route path={`${CONTACT_ROUTE}/:siteId`} component={Contact} />
          <Route path={`${PROFILE_ROUTE}/:siteId`} component={Profile} />
          <Route
            path={`${USER_MANAGEMENT_ROUTE}/:siteId`}
            component={UserManagement}
          />
          <Route
            path={FORGOT_PASSWORD_ROUTE}
            component={ForceResetPassword}
            setSignIn={props.storeLoggedInFlag}
          />
        </Navigation>
      ) : (
        loading === false && (
          <>
            <LoginForm
              signIn={props.AuthLoggedIn}
              selected={selected}
              setSignIn={props.storeLoggedInFlag}
            />
          </>
        )
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  AuthDetail: state.loginUserDetail.userDetail,
  AuthLoggedIn: state.loginUserDetail.loggedIn,
  AuthLoading: state.loginUserDetail.loading,
  CSRFToken: state.loginUserDetail.csrf,
});

const mapDispatchToProps = (dispatch) => ({
  userLogout: () => dispatch(userLogout()),
  storeCSRF: (token) => dispatch(storeCSRF(token)),
  storeLoggedInFlag: (flag) => dispatch(storeLoggedInFlag(flag)),
  getUserLoginDetail: (username) => dispatch(getUserLoginDetail(username)),
  getuserloginDetailSuccess: (data) =>
    dispatch(getuserloginDetailSuccess(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
