import moment from "moment";
import api from "../../config/api";
import config from "../../config/config";
import dataTypes from "./types/dataTypes";

const { GET_DAILY_DATA, GET_DAILY_MIN_DATA } = config.api_endpoint;

const { GET_DATA_COUNT, GET_DATA_COUNT_SUCCESS, GET_DATA_COUNT_FAIL } =
  dataTypes;

const fetchingDataCount = () => {
  return {
    type: GET_DATA_COUNT,
  };
};

const fetchingDataCountSuccess = (data) => {
  return {
    type: GET_DATA_COUNT_SUCCESS,
    payload: data,
  };
};

const fetchingDataCountFail = () => {
  return {
    type: GET_DATA_COUNT_FAIL,
  };
};

const getCurrentSite = config.getCurrentSite;

export const fetchDataCount = (from_ts, to_ts) => {
  console.log("fetchDataCount from_ts, to_ts:", from_ts, to_ts);
  let past_from_ts = moment(from_ts, "YYYY-MM-DD")
    .subtract(1, "years").format("YYYY-MM-DD");
  let past_to_ts = moment(to_ts, "YYYY-MM-DD")
    .subtract(1, "years").format("YYYY-MM-DD");

  return (dispatch) => {
    dispatch(fetchingDataCount());
    api
      .get(GET_DAILY_MIN_DATA, {
        from_ts,
        to_ts,
        site_id: getCurrentSite(),
      })
      .then((res) => {
        if (res.status == "Success") {

          api
            .get(GET_DAILY_MIN_DATA, {
              from_ts: past_from_ts,
              to_ts: past_to_ts,
              site_id: getCurrentSite(),
            })
            .then((res2) => {
              if (res2.status == "Success") {
                return dispatch(fetchingDataCountSuccess({
                  from_ts,
                  to_ts,
                  past_from_ts,
                  past_to_ts,
                  current: res,
                  past: res2
                }));
              } else {
                return dispatch(fetchingDataCountFail());
              }
              
            })
            .catch((err2) => {
              console.log("err2", err2);
              return dispatch(fetchingDataCountFail());
            });
        } else {
          return dispatch(fetchingDataCountFail());
        }
      })
      .catch((err) => {
        console.log("err", err);
        return dispatch(fetchingDataCountFail());
      });
  };
};
